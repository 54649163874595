<template>
    <v-dialog v-model="dialog" tile flat max-width="80%" v-if="value">
        <!-- {{value}} -->
        <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn text small block v-bind="attrs" v-on="on">View</v-btn> -->
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-avatar size="60">
                    <v-img src="@/assets/images/avatars/profil.svg"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title><strong>{{ value.job.title }}</strong></v-list-item-title>
                    <v-list-item-subtitle v-if="value.user">
                        <span>{{ value.user.firstname }}</span> &nbsp;&nbsp;
                        <span class="secondary--text">({{ value.user.email }})</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-card class="pa-8" max-width="100%" outlined tile>
            <!-- {{value}} -->
            <div class="d-flex flex-row">
                <h2 class="pa-0">
                    <strong>{{ value.job.title }}</strong>
                </h2>
                <v-spacer></v-spacer>
                <v-btn icon class="subsecondary" @click="dialog = false">
                    <v-icon color="secondary">{{ icons.mdiClose }}</v-icon>
                </v-btn>
            </div>
            <div>
                <v-list three-line>
                    <v-list-item two-line class="pa-0">
                        <v-list-item-avatar size="50">
                            <v-img src="@/assets/images/avatars/profil.svg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle v-if="value.user">
                                {{ value.user.firstname }}
                                {{ value.user.lastname }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                {{ value.user.email }} <br />
                                {{ value.user.phonenumber }}
                                <!-- <span>{{ value.location + ',' + value.country.name }}</span> -->
                            </v-list-item-subtitle>
                            <v-list-item-subtitle outlined>
                                <!-- <v-icon>{{ icons.mdiBriefcase }}</v-icon> -->
                                <span>{{ value.user.country.name }}</span>
                            </v-list-item-subtitle>
                            <div class="feed-back-job-outline" v-html="value.about"></div>

                        </v-list-item-content>

                    </v-list-item>
                </v-list>
            </div>
            <v-divider></v-divider>
            <div class="d-flex flex-row my-4">
                <span class="mr-4">
                    <v-icon color="error" class="mr-1">{{ icons.mdiAlertOutline }}</v-icon>
                    Date: {{ value.dateline }}
                </span>
                <v-spacer></v-spacer>
                <router-link :to="{ name: 'user.views', params: {ids: this.value.user.id, f_id: 'dldlf45'} }" target="_blank"
                    class="text-decoration-none">
                    <v-btn text class="subsecondary" color="secondary" small>
                        View profile
                    </v-btn>
                </router-link>
            </div>
            <v-divider></v-divider>
            <p><b>Job motivation</b></p>
            <div class="px-0" v-html="value.response"></div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase } from '@mdi/js'
import { VueEditor } from "vue2-editor";
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        value: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            dialog: false
        }
    },
    setup() {
        const icons = { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase }

        return {
            icons
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';

.feed-back-job-outline {
    //border-radius: 0.5em;
    padding: 0px 0.3em;
    border-top: solid 1px #ececec;
    border-bottom: solid 1px #ececec;
}
</style>