<template>
    <div>
        <card-header :header="topheaders"></card-header>
        <div class="mb-4">
            <v-btn text rounded color="secondary" @click="backToList">
                <v-icon class="mr-2">{{ icons.mdiKeyboardBackspace }}</v-icon>
                Back to home
            </v-btn>
        </div>
        <v-card class="">
            <v-card-text>
                <v-text-field v-model="search" :prepend-inner-icon="icons.mdiMagnify" class="search-field" hide-details
                    dense outlined clearable>
                </v-text-field>
            </v-card-text>
            <v-data-table :headers="headers" :items="vacancieslist" :search="search" :loading="loaderdata"
                loading-text="Loading your companies... Please wait" sort-by="object" class="elevation-1 row-pointer"
                style="cursor: pointer">
                <!-- </v-data-table>@click:row="(item) => viewFeedBack(item)" flat> -->
                <template v-slot:item="{ item }">
                    <view-feedback :value="item"></view-feedback>
                    <!-- {{item}} -->
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiEyeOffOutline, mdiMagnify, mdiEye, mdiDotsHorizontal, mdiShapeRectanglePlus, mdiKeyboardBackspace } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import ViewFeedback from "@/views/dashboard/vacancies/components/ViewFeedback.vue"
import CardHeader from '@/views/dashboard/components/CardHeader.vue'

export default {
    components: {
        ViewFeedback,
        CardHeader
    },
    data() {
        return {
            topheaders: {
                titlepage: 'VACANCIES',
                icon: null,
            },
            loading: false,
            loaderdata: true,
            vacancieslist: [],
            // dessert: [],
            dialog_on: false
        }
    },
    beforeMount() {
        this.loaderdata = true
        Drequest.api(`lazyloading.jobresponse?dfilters=on&enterprise.id:eq=${this.$store.getters.getCompany.id}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.vacancieslist.push(value)
                    });
                    // console.log(this.vacancieslist)
                    this.loaderdata = false
                }
                else {
                    this.loaderdata = false
                }
            })
            .catch((err) => {
                alert('Server error')
                this.loaderdata = false
            });

    },
    methods: {
        opendialog() {
            this.dialog_on = true
        },
        handleRowClick(value, srcItem) {
            if (srcItem === 'eye') {
                console.log(this.vacancieslist.indexOf(value))
            }
            else if (srcItem === 'share') {
                console.log(this.vacancieslist.indexOf(value))
            } else {
                console.log(JSON.stringify(value))
            }
        },
        highlightClickedRow(value) {
            const tr = value.target.parentNode;
            tr.classList.add('primary');
        },
        editvacancies() {
            alert('edit')
        },
        deletevacancies() {
            alert('delete')
        },
        sharevacancies() {
            alert('share')
        },

        backToList() {
            this.$router.push({ name: 'company.vacancies' })
        },
        viewFeedBack(item) {
            this.dialog_on = true
            // console.log(item)
            // const companie = this.vacancieslist.find(element => element.id == item.id);
            // this.$router.push({ name: 'company.home', params: { company: companie.company.name } })
            // this.$router.push({ name: 'company', params: { ids: this.vacancieslist.indexOf(item), id: md5(this.vacancieslist.indexOf(item)) } })
        },
        deleteItem(item) {
            this.editedIndex = this.vacancieslist.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.vacancieslist.splice(this.editedIndex, 1)
            this.closeDelete()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.vacancieslist[this.editedIndex], this.editedItem)
            } else {
                this.vacancieslist.push(this.editedItem)
            }
            this.close()
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    setup(props) {
        // button data-table action
        const btnSingle = ref(false)
        const btnRow = ref(false)
        const icons = { mdiEyeOffOutline, mdiMagnify, mdiEye, mdiDotsHorizontal, mdiShapeRectanglePlus, mdiKeyboardBackspace }
        let search = ref('')
        const headers = [
            {
                value: 'job',
                align: 'start',
                sortable: true,
            },
        ]
        return {
            // button data-table action
            btnRow, btnSingle,
            icons,
            search,
            headers
        }
    },
}
</script>

<style lang="scss" scoped>
//.row-pointer>.v-data-table__wrapper>table>tbody>tr:hover {
//   cursor: pointer;
//}

.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}
</style>